import { Site } from '../../types';
import React, { useEffect, useState } from 'react';
import { Prediction, AggregateValue, BaselineValue, EKZChartData } from '../DataValues';
import Chart from '../../components/ekz/Chart';
import Grid from '@mui/material/Unstable_Grid2';
import { getDates, groupValuesByHour } from '../../util';
import axios from 'axios';
interface DashboardProps {
  serverUrl: string;
  sites: Site[];
}

const DashboardTuya: React.FC<DashboardProps> = ({ sites, serverUrl }) => {
  const [chartDataAllSources, setChartDataAllSources] = useState<EKZChartData>({
    consumptionValue: null,
    consumptionEVsValue: null,
    consumptionSiteValue: null,
    consumptionEVsHistoricalValue: null,
    productionValue: null,
    flexibilityValue: null,
    consumptionForecast: null,
    productionForecast: null,
    flexibilityForecast: null,
    events: null,
    invitations: null,
    baselineValue: null
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [chartDataEVs, setChartDataEVs] = useState<EKZChartData>({
    consumptionValue: null,
    consumptionEVsValue: null,
    consumptionSiteValue: null,
    consumptionEVsHistoricalValue: null,
    productionValue: null,
    flexibilityValue: null,
    consumptionForecast: null,
    productionForecast: null,
    flexibilityForecast: null,
    events: null,
    invitations: null,
    baselineValue: null
  });
  const [chartDataMetervalue, setChartDataMetervalue] = useState<EKZChartData>({
    consumptionValue: null,
    consumptionEVsValue: null,
    consumptionSiteValue: null,
    consumptionEVsHistoricalValue: null,
    productionValue: null,
    flexibilityValue: null,
    consumptionForecast: null,
    productionForecast: null,
    flexibilityForecast: null,
    events: null,
    invitations: null,
    baselineValue: null
  });
  const [chartDataMetervalueReworked, setChartDataMetervalueReworked] = useState<EKZChartData>({
    consumptionValue: null,
    consumptionEVsValue: null,
    consumptionSiteValue: null,
    consumptionEVsHistoricalValue: null,
    productionValue: null,
    flexibilityValue: null,
    consumptionForecast: null,
    productionForecast: null,
    flexibilityForecast: null,
    events: null,
    invitations: null,
    baselineValue: null
  });
  const [chartDataDeterministicBaseline, setChartDataDeterministicBaseline] =
    useState<EKZChartData>({
      consumptionValue: null,
      consumptionEVsValue: null,
      consumptionSiteValue: null,
      consumptionEVsHistoricalValue: null,
      productionValue: null,
      flexibilityValue: null,
      consumptionForecast: null,
      productionForecast: null,
      flexibilityForecast: null,
      events: null,
      invitations: null,
      baselineValue: null
    });

  const [activeAggregatePowerForAllSources, setAggregateActivePowerForAllSources] = useState<
    AggregateValue[]
  >([]);
  const [activeAggregatePowerForEVsPrediction, setActiveAggregatePowerForEVsPrediction] = useState<
    Prediction[]
  >([]);
  const [activeAggregatePowerForEVs, setAggregateActivePowerForEVs] = useState<AggregateValue[]>(
    []
  );
  const [activeAggregatePowerForMetervalue, setAggregateActivePowerForMetervalue] = useState<
    AggregateValue[]
  >([]);
  const [
    activeAggregatePowerForMetervaluePrediction,
    setAggregateActivePowerForMetervaluePrediction
  ] = useState<Prediction[]>([]);
  const [
    activeAggregatePowerForMetervaluePredictionPast,
    setAggregateActivePowerForMetervaluePredictionPast
  ] = useState<Prediction[]>([]);
  const [reworkedActiveAggregatePowerForMetervalue, setReworkedAggregateActivePowerForMetervalue] =
    useState<AggregateValue[]>([]);
  const [
    reworkedActiveAggregatePowerForMetervaluePrediction,
    setReworkedAggregateActivePowerForMetervaluePrediction
  ] = useState<Prediction[]>([]);
  const [
    reworkedActiveAggregatePowerForMetervaluePredictionPast,
    setReworkedAggregateActivePowerForMetervaluePredictionPast
  ] = useState<Prediction[]>([]);
  const [deterministicBaseline, setDeterministicBaseline] = useState<BaselineValue[]>([]);

  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function fillIntervals(data: any) {
    const filledData = [];

    // Helper function to add minutes to a date
    function addMinutes(date: any, minutes: any) {
      return new Date(date.getTime() + minutes * 60000);
    }

    for (let i = 0; i < data.length - 1; i++) {
      // Push the current data point
      filledData.push(data[i]);

      // Get the current timestamp and the next timestamp
      const currentTime = new Date(data[i].predicted_for);
      const nextTime = new Date(data[i + 1].predicted_for);

      // Generate intermediate timestamps at 15-minute intervals
      let nextInterval = addMinutes(currentTime, 15);

      while (nextInterval < nextTime) {
        filledData.push({
          predicted_for: nextInterval.toISOString(),
          value: null
        });
        nextInterval = addMinutes(nextInterval, 15);
      }
    }

    // Push the last data point
    filledData.push(data[data.length - 1]);

    return filledData;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const mockPredictionData = (historicalData: any) => {
    const historical = JSON.parse(JSON.stringify(historicalData));
    historical.forEach((item: any) => {
      const timepointAsDate = new Date(item.timepoint);
      timepointAsDate.setDate(timepointAsDate.getDate() + 5);
      item.predicted_for = timepointAsDate.toISOString();
    });
    const pastPredictionData = JSON.parse(JSON.stringify(historicalData)).map(
      (item: AggregateValue) => ({
        predicted_for: item.timepoint,
        value: item.value * 2
      })
    );
    const combinedData = [...pastPredictionData, ...historical];
    return combinedData;
  };

  useEffect(() => {
    const token = localStorage.getItem('user_token');

    const fetchAggregateActivePowerForAllSources = async () => {
      const { fiveDaysAgoDate } = getDates();
      const dateNow = new Date();

      try {
        const aggregateActivePowerForAllSourcesResponse = await axios.get(
          `${serverUrl}/chronicle_values/2`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            },
            params: {
              start: fiveDaysAgoDate,
              end: dateNow
            }
          }
        );
        setAggregateActivePowerForAllSources(aggregateActivePowerForAllSourcesResponse.data);
      } catch (error) {
        console.error('Error fetching aggregate active power for all sites data:', error);
      }
    };
    const fetchAggregateActivePowerForEVs = async () => {
      const { fiveDaysAgoDate } = getDates();
      const dateNow = new Date();

      try {
        const aggregateActivePowerForEVsResponse = await axios.get(
          `${serverUrl}/chronicle_values/3`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            },
            params: {
              start: fiveDaysAgoDate,
              end: dateNow
            }
          }
        );
        setAggregateActivePowerForEVs(aggregateActivePowerForEVsResponse.data);

        const EVsResponse = JSON.parse(JSON.stringify(aggregateActivePowerForEVsResponse.data));
        EVsResponse.forEach((item: any) => {
          const timepointAsDate = new Date(item.timepoint);
          timepointAsDate.setDate(timepointAsDate.getDate() + 5);
          item.predicted_for = timepointAsDate.toISOString();
        });
        const pastPredictionData = JSON.parse(
          JSON.stringify(aggregateActivePowerForEVsResponse.data)
        ).map((item: AggregateValue) => ({
          predicted_for: item.timepoint,
          value: item.value * 2
        }));
        const combinedData = [...pastPredictionData, ...EVsResponse];
        setActiveAggregatePowerForEVsPrediction(combinedData);
      } catch (error) {
        console.error('Error fetching aggregate active power for all sites data:', error);
      }
    };
    const fetchAggregateActivePowerForMetervalue = async () => {
      const { fiveDaysAgoDate } = getDates();
      const dateNow = new Date();

      try {
        const aggregateActivePowerForMetervalueResponse = await axios.get(
          `${serverUrl}/chronicle_values/5`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            },
            params: {
              start: fiveDaysAgoDate,
              end: dateNow
            }
          }
        );
        setAggregateActivePowerForMetervalue(aggregateActivePowerForMetervalueResponse.data);
      } catch (error) {
        console.error('Error fetching aggregate active power for all sites data:', error);
      }
    };
    const fetchAggregateActivePowerForMetervaluePredictionPast = async () => {
      const { fiveDaysAgoDate } = getDates();
      const dateNow = new Date();

      try {
        const aggregateActivePowerForMetervalueResponsePrediction = await axios.get(
          `${serverUrl}/chronicle_predictions/5`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            },
            params: {
              model_id: 2,
              start: fiveDaysAgoDate,
              end: dateNow
            }
          }
        );
        setAggregateActivePowerForMetervaluePredictionPast(
          aggregateActivePowerForMetervalueResponsePrediction.data
        );
      } catch (error) {
        console.error('Error fetching aggregate active power for all sites data:', error);
      }
    };
    const fetchAggregateActivePowerForMetervaluePrediction = async () => {
      const dateNow = new Date();
      const twoDaysLater = new Date(dateNow);
      twoDaysLater.setDate(dateNow.getDate() + 2);

      try {
        const aggregateActivePowerForMetervalueResponsePrediction = await axios.get(
          `${serverUrl}/chronicle_predictions/5`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            },
            params: {
              model_id: 2,
              start: dateNow,
              end: twoDaysLater
            }
          }
        );
        let prediction = aggregateActivePowerForMetervalueResponsePrediction.data.map(
          (item: any) => {
            return {
              predicted_for: item.predicted_for,
              value: item.value
            };
          }
        );
        // prediction = fillIntervals(prediction);
        setAggregateActivePowerForMetervaluePrediction(prediction);
      } catch (error) {
        console.error('Error fetching aggregate active power for all sites data:', error);
      }
    };
    const fetchReworkedAggregateActivePowerForMetervalue = async () => {
      const { fiveDaysAgoDate } = getDates();
      const dateNow = new Date();

      try {
        const reworkedAggregateActivePowerForMetervalueResponse = await axios.get(
          `${serverUrl}/chronicle_values/7`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            },
            params: {
              start: fiveDaysAgoDate,
              end: dateNow
            }
          }
        );
        setReworkedAggregateActivePowerForMetervalue(
          reworkedAggregateActivePowerForMetervalueResponse.data
        );
      } catch (error) {
        console.error('Error fetching aggregate active power for all sites data:', error);
      }
    };
    const fetchReworkedAggregateActivePowerForMetervaluePredictionPast = async () => {
      const { fiveDaysAgoDate } = getDates();
      const dateNow = new Date();

      try {
        const reworkedAggregateActivePowerForMetervalueResponsePrediction = await axios.get(
          `${serverUrl}/chronicle_predictions/7`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            },
            params: {
              model_id: 4,
              start: fiveDaysAgoDate,
              end: dateNow
            }
          }
        );
        setReworkedAggregateActivePowerForMetervaluePredictionPast(
          reworkedAggregateActivePowerForMetervalueResponsePrediction.data
        );
      } catch (error) {
        console.error('Error fetching aggregate active power for all sites data:', error);
      }
    };
    const fetchReworkedAggregateActivePowerForMetervaluePrediction = async () => {
      const dateNow = new Date();
      const twoDaysLater = new Date(dateNow);
      twoDaysLater.setDate(dateNow.getDate() + 2);

      try {
        const reworkedAggregateActivePowerForMetervalueResponsePrediction = await axios.get(
          `${serverUrl}/chronicle_predictions/7`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            },
            params: {
              model_id: 4,
              start: dateNow,
              end: twoDaysLater
            }
          }
        );
        let prediction = reworkedAggregateActivePowerForMetervalueResponsePrediction.data.map(
          (item: any) => {
            return {
              predicted_for: item.predicted_for,
              value: item.value
            };
          }
        );
        // prediction = fillIntervals(prediction);
        setReworkedAggregateActivePowerForMetervaluePrediction(prediction);
      } catch (error) {
        console.error('Error fetching aggregate active power for all sites data:', error);
      }
    };
    const createDeterministicBaselineTimeSeries = async () => {
      const deterministicBaselineTimeSeries = [];
      const { fiveDaysAgoDate } = getDates();
      const dateNow = new Date();

      function checkTime(hour: number) {
        if (hour >= 19 || hour < 0) {
          return 0;
        } else {
          return 1;
        }
      }

      for (let date = fiveDaysAgoDate; date <= dateNow; date.setHours(date.getHours() + 1)) {
        const hour = date.getHours();
        const value = checkTime(hour);

        deterministicBaselineTimeSeries.push({
          timepoint: date.toString(),
          value: value
        });

        setDeterministicBaseline(deterministicBaselineTimeSeries);
      }
    };
    if (token !== '') {
      fetchAggregateActivePowerForAllSources();
      fetchAggregateActivePowerForEVs();
      fetchAggregateActivePowerForMetervalue();
      fetchAggregateActivePowerForMetervaluePredictionPast();
      fetchAggregateActivePowerForMetervaluePrediction();
      fetchReworkedAggregateActivePowerForMetervalue();
      fetchReworkedAggregateActivePowerForMetervaluePredictionPast();
      fetchReworkedAggregateActivePowerForMetervaluePrediction();
      createDeterministicBaselineTimeSeries();
    }
  }, [serverUrl, sites]);

  useEffect(() => {
    const newChartDataAllSources: EKZChartData = {
      consumptionValue: null,
      consumptionEVsValue: activeAggregatePowerForMetervalue,
      consumptionSiteValue: activeAggregatePowerForAllSources,
      consumptionEVsHistoricalValue: null,
      productionValue: null,
      flexibilityValue: null,
      consumptionForecast: null,
      productionForecast: null,
      flexibilityForecast: null,
      events: null,
      invitations: null,
      baselineValue: null
    };
    setChartDataAllSources(newChartDataAllSources);
  }, [activeAggregatePowerForAllSources, activeAggregatePowerForMetervalue]);

  useEffect(() => {
    const historicalEVsConsumption = activeAggregatePowerForEVs.map((item) => ({
      timepoint: item.timepoint,
      value: item.value * 1.2
    }));
    const newChartDataEVs: EKZChartData = {
      consumptionValue: activeAggregatePowerForEVs,
      consumptionSiteValue: null,
      consumptionEVsValue: null,
      consumptionEVsHistoricalValue: historicalEVsConsumption,
      productionValue: null,
      flexibilityValue: null,
      consumptionForecast: activeAggregatePowerForEVsPrediction,
      productionForecast: null,
      flexibilityForecast: null,
      events: null,
      invitations: null,
      baselineValue: null
    };
    setChartDataEVs(newChartDataEVs);
  }, [activeAggregatePowerForEVs, activeAggregatePowerForEVsPrediction]);

  useEffect(() => {
    const historicalPredictions = activeAggregatePowerForMetervaluePredictionPast.map((item) => ({
      timepoint: item.predicted_for,
      value: item.value
    }));

    const consumptionAveragedByHour = groupValuesByHour(activeAggregatePowerForMetervalue);
    const newChartDataMetervalue: EKZChartData = {
      consumptionValue: consumptionAveragedByHour,
      consumptionSiteValue: null,
      consumptionEVsValue: null,
      consumptionEVsHistoricalValue: historicalPredictions,
      productionValue: null,
      flexibilityValue: null,
      consumptionForecast: activeAggregatePowerForMetervaluePrediction,
      productionForecast: null,
      flexibilityForecast: null,
      events: null,
      invitations: null,
      baselineValue: null
    };
    setChartDataMetervalue(newChartDataMetervalue);
  }, [
    activeAggregatePowerForMetervalue,
    activeAggregatePowerForMetervaluePrediction,
    activeAggregatePowerForMetervaluePredictionPast
  ]);

  useEffect(() => {
    const historicalPredictions = reworkedActiveAggregatePowerForMetervaluePredictionPast.map(
      (item) => ({
        timepoint: item.predicted_for,
        value: item.value
      })
    );
    historicalPredictions.sort(
      (a, b) => new Date(a.timepoint).getTime() - new Date(b.timepoint).getTime()
    );
    reworkedActiveAggregatePowerForMetervaluePrediction.sort(
      (a, b) => new Date(a.predicted_for).getTime() - new Date(b.predicted_for).getTime()
    );

    const consumptionAveragedByHour = groupValuesByHour(reworkedActiveAggregatePowerForMetervalue);
    const newChartDataMetervalueReworked: EKZChartData = {
      consumptionValue: consumptionAveragedByHour,
      consumptionSiteValue: null,
      consumptionEVsValue: null,
      consumptionEVsHistoricalValue: historicalPredictions,
      productionValue: null,
      flexibilityValue: null,
      consumptionForecast: reworkedActiveAggregatePowerForMetervaluePrediction,
      productionForecast: null,
      flexibilityForecast: null,
      events: null,
      invitations: null,
      baselineValue: null
    };
    setChartDataMetervalueReworked(newChartDataMetervalueReworked);
  }, [
    reworkedActiveAggregatePowerForMetervalue,
    reworkedActiveAggregatePowerForMetervaluePrediction,
    reworkedActiveAggregatePowerForMetervaluePredictionPast
  ]);

  useEffect(() => {
    const newChartDataDeterministicBaseline: EKZChartData = {
      consumptionValue: null,
      consumptionSiteValue: null,
      consumptionEVsValue: null,
      consumptionEVsHistoricalValue: null,
      productionValue: null,
      flexibilityValue: null,
      consumptionForecast: null,
      productionForecast: null,
      flexibilityForecast: null,
      events: null,
      invitations: null,
      baselineValue: deterministicBaseline
    };
    setChartDataDeterministicBaseline(newChartDataDeterministicBaseline);
  }, [deterministicBaseline]);

  return (
    <Grid container spacing={3} style={{ marginBottom: '6px' }}>
      <Grid sm={12}>
        <Chart
          data={chartDataAllSources}
          unit={'MW'}
          headingText={'Site and EV consumption'}
          nDecimals={1}
          timeRangeButtons={[
            {
              type: 'day',
              count: 1,
              text: '1d'
            },
            {
              type: 'day',
              count: 3,
              text: '3d'
            },
            {
              type: 'all',
              text: 'All'
            }
          ]}
          shareTooltip={true}
        />
      </Grid>
      <Grid sm={12}>
        <Chart
          data={chartDataMetervalue}
          unit={'kW'}
          headingText={'EV consumption and forecast'}
          nDecimals={1}
          timeRangeButtons={[
            {
              type: 'day',
              count: 1,
              text: '1d'
            },
            {
              type: 'day',
              count: 3,
              text: '3d'
            },
            {
              type: 'all',
              text: 'All'
            }
          ]}
          shareTooltip={true}
        />
      </Grid>
      <Grid sm={12}>
        <Chart
          data={chartDataMetervalueReworked}
          unit={'kW'}
          headingText={'Baseline reworked EV consumption and forecast'}
          nDecimals={1}
          timeRangeButtons={[
            {
              type: 'day',
              count: 1,
              text: '1d'
            },
            {
              type: 'day',
              count: 3,
              text: '3d'
            },
            {
              type: 'all',
              text: 'All'
            }
          ]}
          shareTooltip={true}
        />
      </Grid>
      <Grid sm={12}>
        <Chart
          data={chartDataDeterministicBaseline}
          unit={'%'}
          headingText={'Baseline charging schedule'}
          nDecimals={0}
          timeRangeButtons={[
            {
              type: 'day',
              count: 1,
              text: '1d'
            },
            {
              type: 'day',
              count: 3,
              text: '3d'
            },
            {
              type: 'all',
              text: 'All'
            }
          ]}
          shareTooltip={false}
        />
      </Grid>
    </Grid>
  );
};

export default DashboardTuya;
