import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Input,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material';
import Slider from '@mui/material/Slider';

import { Site } from '../../types';
import StatusChip from '../ekz/StatusChip';

interface Filter {
  name: string;
  numberOfConnectors: number;
}

interface TableFilterProps {
  data: Site[];
  column: string;
  onFilter: (filteredData: Site[]) => void;
  largestConnectorCount: number;
  filters: Filter;
  setFilters: React.Dispatch<React.SetStateAction<Filter>>;
}

const TableFilter: React.FC<TableFilterProps> = ({
  data,
  column,
  onFilter,
  largestConnectorCount,
  filters,
  setFilters
}) => {
  let columnName: string = '';
  if (column === 'name') {
    columnName = 'site name';
  }
  if (column === 'numberOfConnectors') {
    columnName = 'number of connectors';
  }

  const handleTextFilterChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newFilterValue = event.target.value;
    setFilters((filters) => ({
      ...filters,
      [column]: newFilterValue
    }));
  };

  const handleRangeFilterChange = (event: Event, newValue: number | number[] | string) => {
    const newFilterValue = newValue;
    setFilters((filters) => ({
      ...filters,
      [column]: newFilterValue
    }));
  };

  return (
    <Box sx={{ flex: 1 }}>
      <Stack direction="column" spacing={2} padding={2} sx={{ width: '400px', marginX: 'auto' }}>
        <label>Filter by {columnName}</label>
        {column === 'name' && (
          <Input
            placeholder={`Filter by ${column}`}
            value={filters['name']}
            onChange={handleTextFilterChange}
          />
        )}
        {column === 'numberOfConnectors' && (
          <Slider
            min={1}
            max={largestConnectorCount}
            value={filters['numberOfConnectors']}
            valueLabelDisplay="auto"
            marks={[
              { value: 1, label: 1 },
              { value: largestConnectorCount, label: largestConnectorCount }
            ]}
            size="small"
            onChange={handleRangeFilterChange}
          />
        )}
      </Stack>
    </Box>
  );
};

interface TableProps {
  sites: Site[];
  setHighlightedSite: React.Dispatch<React.SetStateAction<number | null>>;
}

const SitesTable: React.FC<TableProps> = ({ sites, setHighlightedSite }) => {
  const [filters, setFilters] = useState<Filter>({ name: '', numberOfConnectors: 1 });
  const [data, setData] = useState<Site[]>(sites);
  const tableEl = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  let largestConnectorCount = 0;
  sites.forEach((site) => {
    if (site.params.number_of_connectors > largestConnectorCount) {
      largestConnectorCount = site.params.number_of_connectors;
    }
  });

  const displayedSites = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  useEffect(() => {
    const filteredData = sites.filter((row) => {
      return (
        row['params']['number_of_connectors'] === Number(filters['numberOfConnectors']) &&
        String(row['name']).toLowerCase().includes(String(filters['name']).toLowerCase())
      );
    });

    handleFilteredData(filteredData)
  }, [filters, sites])

  const handleFilteredData = (filteredData: Site[]) => {
    setData(filteredData); // Update the data state with the filtered results
    setPage(0);
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        sx={{ flex: 'auto', padding: '10px' }}
        justifyContent="center"
      >
        <TableFilter
          data={sites}
          column="name"
          onFilter={handleFilteredData}
          largestConnectorCount={largestConnectorCount}
          filters={filters}
          setFilters={setFilters}
        />
        <TableFilter
          data={sites}
          column="numberOfConnectors"
          onFilter={handleFilteredData}
          largestConnectorCount={largestConnectorCount}
          filters={filters}
          setFilters={setFilters}
        />
      </Stack>
      <TableContainer ref={tableEl} style={{ height: '700px', overflow: 'auto' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <b>Site ID</b>
              </TableCell>
              <TableCell align="center">
                <b>Site name</b>
              </TableCell>
              <TableCell align="center">
                <b>Canton</b>
              </TableCell>
              <TableCell align="center">
                <b>Postcode</b>
              </TableCell>
              <TableCell align="center">
                <b>Status</b>
              </TableCell>
              <TableCell align="center">
                <b>Number of connectors</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedSites.length === 0 && filters.name === '' && (
              <TableRow>
                <TableCell colSpan={6}>
                  No sites found with {filters.numberOfConnectors} connectors
                </TableCell>
              </TableRow>
            )}
            {displayedSites.length === 0 && filters.name !== '' && (
              <TableRow>
                <TableCell colSpan={6}>
                  No sites found with name {filters.name} and {filters.numberOfConnectors}{' '}
                  connectors
                </TableCell>
              </TableRow>
            )}
            {displayedSites.length > 0 &&
              displayedSites.map((site) => (
                <TableRow
                  key={site.site_id}
                  hover={true}
                  onMouseEnter={() => setHighlightedSite(site.site_id)}
                  onMouseLeave={() => setHighlightedSite(null)}
                  onClick={() => navigate(`/site/${site.site_id}/details`)}
                >
                  <TableCell align="center">{site.site_id}</TableCell>
                  <TableCell align="center">{site.name}</TableCell>
                  <TableCell align="center">{site.params.canton}</TableCell>
                  <TableCell align="center">{site.postcode}</TableCell>
                  <TableCell align="center">
                    {site.params.site_not_responding ? (
                      <StatusChip status="offline" />
                    ) : (
                      <StatusChip status="online" />
                    )}
                  </TableCell>
                  <TableCell align="center">{site.params.number_of_connectors}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default SitesTable;
